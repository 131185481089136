import * as React from "react"

import Layout from "../components/layout"

const Faq = () => {
    return (
        <Layout>
            FAQ
        </Layout>
    )
}

export default Faq